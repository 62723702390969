<template>
<div class="menulist">
  <ul>
    <li @mouseover="isact=true">
      <img src="../../static/imges/二维码.png" alt="">
      <span>{{$t('m.live.download')}}</span>
    </li>
    <div v-show="isact" class="install">
      <img class="computer" src="../../static/imges/电脑.png"
       @click="toinstall">
      <img class="phone" src="../../static/imges/手机.png"
      @click="toinstall">
    </div>
    <li @click="toinstall">
      <img src="../../static/imges/开播.png" alt="">
      <span>{{$t('m.live.Go_live')}}</span>
    </li>
    <li @click="toCourse">
      <img src="../../static/imges/帮助.png" alt="">
      <span>{{$t('m.live.help')}}</span>
    </li>
    <li  @click="jubao=true">
      <img src="../../static/imges/举报.png" alt="">
      <span>{{$t('m.live.report_an_offense')}}</span>
    </li>
    <div class="phonenum" v-show="jubao">
      <span>021-83512580</span>
    </div>
  </ul>
</div>
</template>

<script>
import { ref } from 'vue-demi'
export default {
  name: 'menuList',
  data () {
    return {
      isact: ref(false), // 下载页面
      jubao: ref(false)
    }
  },
  components: {},
  methods: {
    toinstall () {
      const routeData = this.$router.resolve({
        path: '/live/install'
      })
      window.open(routeData.href, '_blank')
    },
    toCourse () {
      // this.$router.push('/live/course')
      window.open('https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/admin/file/livegjpz.pdf')
    }
  },
  mounted () {

  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
.menulist{
  width: 67px;
  height: 329px;
  background: #FFFFFF;
  position: fixed;
  right: 3%;
  bottom: 151px;
  ul{
    height: 100%;
    box-sizing: border-box;
    padding: 10px ;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: center;
    position: relative;
    li{
      display: flex;
      flex-flow: column;
      cursor: pointer;
      img{
        margin: 0 auto;
        width: 28px;
        height: 28px;
      }
      span{
        text-align: center;
        line-height: 30px;
      }
    }
    .install{
      width: 150px;
      height: 230px;
      background: #FFFFFF;
      opacity: 0.5;
      border-radius: 0px 0px 0px 34px;
      position: absolute;
      left: -150px;
      top: 0;
      display: flex;
      flex-flow: column;
      justify-content: space-around;
      img{
        cursor: pointer;
      }
      .computer{
        margin: 0 auto;
        width: 74px;
        height: 64px;
      }
      .phone{
        margin: 0 auto;
        width: 43px;
        height: 68px;
      }
    }
    .phonenum{
      height: 100px;
      background: #FFFFFF;
      font-size: 16px;
      position: absolute;
      left: -75px;
      bottom: 0;
      line-height: 100px;
      text-align: center;
    }
  }
}
</style>
